import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import { OutboundLink } from 'gatsby-plugin-gtag';
import PrimaryBox from 'components/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/How_to_add_your_camera/PrimaryBox';
import NavButtons from 'components/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/NavButtons';
import ForumBox from 'components/Motion_Detection/INSTAR_Cloud/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "How to add your camera to Alexa",
  "path": "/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/How_to_add_your_camera/",
  "dateChanged": "2020-11-06",
  "author": "Mike Polinowski",
  "excerpt": "How to Use INSTAR Cloud Alexa Skill",
  "image": "./MD_SearchThumb_Cloud_Alexa.png",
  "social": "/images/Search/MD_SearchThumb_Cloud_Alexa.webp",
  "toc": "/images/Search/TOC_Icons/TOC_Icons_WebUI_Cloud.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='How to add your camera to Alexa' dateChanged='2020-11-06' author='Mike Polinowski' tag='INSTAR IP Camera' description='Store your alarm recordings safely in our INSTAR Cloud Service and comfortably screen your alarm videos in your web browser.' image='/images/Search/MD_SearchThumb_Cloud_Alexa.png' twitter='/images/Search/MD_SearchThumb_Cloud_Alexa.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/INSTAR_Cloud/Alexa_Cloud_Skills/How_to_add_your_camera/' locationFR='/fr/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/How_to_add_your_camera/' crumbLabel="Alexa" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "enable-p2p-access-to-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#enable-p2p-access-to-your-camera",
        "aria-label": "enable p2p access to your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Enable P2P access to your camera`}</h2>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#enable-p2p-access-to-your-camera"
        }}>{`Enable P2P access to your camera`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#alternatively-via-the-web-interface"
            }}>{`Alternatively via the Web Interface`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#link-to-your-instar-cloud-account"
        }}>{`Link to your INSTAR Cloud account`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#adding-your-instar-camera"
        }}>{`Adding your INSTAR camera`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#use-of-instar-skills"
        }}>{`Use of INSTAR Skills`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#smart-home-skill"
            }}>{`Smart-Home Skill`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#instar-cloud-skill"
            }}>{`INSTAR Cloud Skill`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "enable-p2p-access-to-your-camera-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#enable-p2p-access-to-your-camera-1",
        "aria-label": "enable p2p access to your camera 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Enable P2P access to your camera`}</h2>
    <p>{`Start with the `}<Link to="/Motion_Detection/INSTAR_Cloud/Create_User_Account/" mdxType="Link">{`create an account`}</Link>{` in the `}<OutboundLink href="https://cloud.instar.de" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`INSTAR Cloud`}</OutboundLink>{`. If you already have an account, you can log in there: `}<OutboundLink href="https://cloud.instar.de" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`cloud.instar.de`}</OutboundLink>{`.`}</p>
    <PrimaryBox mdxType="PrimaryBox" />
    <p>{`Once you have logged into the cloud, go to the `}<strong parentName="p">{`Cameras`}</strong>{` tab and add a new camera there if your camera has not already been created there. After the camera is created, go to `}<strong parentName="p">{`Edit`}</strong>{` for the respective camera (see screenshot below):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b42515e00df2f709362a390dcc84e83a/df56e/INSTAR_Cloud_Alexa_Skill_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.56521739130435%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACiElEQVQoz1WQy08TURSHZ+HGRBOSBl+JK4R/QOxKN0iMWxNduzHRaNiYSAyRDbGUl4kLFKQPQNSFBBRBU9BYKEINUJ4FTLE8ysy0dd6dzty5M/fOMYUY4smXX875fbvDJObnfkxNBnpera4sA4CFTAdbtoUcbGFsaQbKa6asm0rR/FMwDBMZJpKLhqQbUhExrFTcli3ZAsWkiklNAqrpFgkUbJAMWsCuA2D/A/+jdBLCkHwSpyLO7wlrY1Rf/WBvfjHWRqyNMZQcLq4MoeRHmhojqcj/jJNfo052jaHfG+FFFQS9EKyG4CUIVB8upPcKHbxF39+k4culMnDxiKAXOi+QaT9Dpv1uwEv6a2l/Dby+CgO1pXx7vRBtU3+GlNmQHm2l/TVHdqAW3lyDoJfEnzPU1sAWwNXAElw1Q+Q9ouy5asaV0q60BSYHVg6o6pq5Q0uVjKuxRNpxdIEZn/j66PETX0t7NDYjKloRYQPZkqIjxwWAwaGRh/UNTb6WucSyKGsIE61o5gTZpoBtwjT7/R5P2alyT3OzL8vzgiDIshSbjqXTaQCoq3tQWVlRVVnR3f2S53lVVTmWjcViPMfZts0k11Mdba33b9+YGB2SZUUraLIsLyzMcxznuhD99rmr89mnkeHIeEQURcMweJ5PJBL5fB5jzPianp49d/7k8WONDfUsywmibGJHEkVFFADg3t07nrITZ06Xd7R37O+ziqoZCEuiqEqiRQgTj8e7urpC4d6ZmVmW48Qc62i8bpo53cYUJqPRnkAwFAovLS7us5ySZx0tqxqWgKi+s8cQQuBgKCGWTVB2HZbCc1s7fZtoW0YA4JYeB+TA4sy8u9Q3tZV9t0t2O8N/AdawRM1tXah1AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b42515e00df2f709362a390dcc84e83a/e4706/INSTAR_Cloud_Alexa_Skill_01.avif 230w", "/en/static/b42515e00df2f709362a390dcc84e83a/d1af7/INSTAR_Cloud_Alexa_Skill_01.avif 460w", "/en/static/b42515e00df2f709362a390dcc84e83a/7f308/INSTAR_Cloud_Alexa_Skill_01.avif 920w", "/en/static/b42515e00df2f709362a390dcc84e83a/74b96/INSTAR_Cloud_Alexa_Skill_01.avif 1188w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b42515e00df2f709362a390dcc84e83a/a0b58/INSTAR_Cloud_Alexa_Skill_01.webp 230w", "/en/static/b42515e00df2f709362a390dcc84e83a/bc10c/INSTAR_Cloud_Alexa_Skill_01.webp 460w", "/en/static/b42515e00df2f709362a390dcc84e83a/966d8/INSTAR_Cloud_Alexa_Skill_01.webp 920w", "/en/static/b42515e00df2f709362a390dcc84e83a/7592c/INSTAR_Cloud_Alexa_Skill_01.webp 1188w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b42515e00df2f709362a390dcc84e83a/81c8e/INSTAR_Cloud_Alexa_Skill_01.png 230w", "/en/static/b42515e00df2f709362a390dcc84e83a/08a84/INSTAR_Cloud_Alexa_Skill_01.png 460w", "/en/static/b42515e00df2f709362a390dcc84e83a/c0255/INSTAR_Cloud_Alexa_Skill_01.png 920w", "/en/static/b42515e00df2f709362a390dcc84e83a/df56e/INSTAR_Cloud_Alexa_Skill_01.png 1188w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b42515e00df2f709362a390dcc84e83a/c0255/INSTAR_Cloud_Alexa_Skill_01.png",
              "alt": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "title": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open the web interface of your camera in a new tab and copy your P2P and Admin Login:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1b1b3a1aefc01997ebf6db60098c82b2/58fee/INSTAR_Cloud_Alexa_Skill_FullHD_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.30434782608696%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACOUlEQVQoz22SW08TURSF+zONCYlBQ4waFZkrIWpibEtVLorxFhONIT57wxeI1logYEOC1kunIEUolM70TNsp7dz4TKfYJ3dyzjov59trZe+YJEnIsoyqKiiKgq6pyKPXOX8vzbmHywzeX+bERJaTU4sMTGQ4NZnh9PTnSAfufGJwKhOd4UdLXHiwSExRNRRVRVZUdF1HUWTi8Tiv1yyezZeZm/vBUmaD9AeDdzmL2WXBzMIBz7M1Xq3VeZKu8vhjlZn5Axa+N4lpqookq4zIKl24JMvcTiXY2hfk1v+wvV6gXdpCFAwqB3UK5SZLP03y24L9mkN+p0l+x2GlaGM7HjFpbIwrqhY5VDU9ip9IJqlW9nA7DgEhbT9AOA5CWEAIfhsCF6/TBo7olR+9Y9Nnhrh28TIjmo5+DEwmk5TLe7iux7/qtDvs7pYJwiOc1iGu5yGETU0IhF3Hrjfw/YDY5NBZrl4a7gH1HnB8fJxSqUS73e4DXdfFtu1IG40GnudhWRbVahUhBPV6nSAIepG7MFXVGD0GplIpNjc3OGw5/UhdkGma0aduoy7QNC2qpkXNsrBrNQLfJybLSn8oqqb1IicSVExB0wXHDSNAq9WKHIZhSKfjQhgAHhx53ZvguHVMUrowpb82siwRT96Cxg5Uc1A3+F85PuT3AwoVH/9XEXclB5boAbsO+3soy9y4mSSffUvx/TRG+iVFo0CxaERqGAV+bxTIfvnK0zerzM6tkHsxy+rUXb7Nz/MXV9qM3MUscgoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1b1b3a1aefc01997ebf6db60098c82b2/e4706/INSTAR_Cloud_Alexa_Skill_FullHD_02.avif 230w", "/en/static/1b1b3a1aefc01997ebf6db60098c82b2/d1af7/INSTAR_Cloud_Alexa_Skill_FullHD_02.avif 460w", "/en/static/1b1b3a1aefc01997ebf6db60098c82b2/7f308/INSTAR_Cloud_Alexa_Skill_FullHD_02.avif 920w", "/en/static/1b1b3a1aefc01997ebf6db60098c82b2/cad33/INSTAR_Cloud_Alexa_Skill_FullHD_02.avif 1051w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1b1b3a1aefc01997ebf6db60098c82b2/a0b58/INSTAR_Cloud_Alexa_Skill_FullHD_02.webp 230w", "/en/static/1b1b3a1aefc01997ebf6db60098c82b2/bc10c/INSTAR_Cloud_Alexa_Skill_FullHD_02.webp 460w", "/en/static/1b1b3a1aefc01997ebf6db60098c82b2/966d8/INSTAR_Cloud_Alexa_Skill_FullHD_02.webp 920w", "/en/static/1b1b3a1aefc01997ebf6db60098c82b2/42749/INSTAR_Cloud_Alexa_Skill_FullHD_02.webp 1051w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1b1b3a1aefc01997ebf6db60098c82b2/81c8e/INSTAR_Cloud_Alexa_Skill_FullHD_02.png 230w", "/en/static/1b1b3a1aefc01997ebf6db60098c82b2/08a84/INSTAR_Cloud_Alexa_Skill_FullHD_02.png 460w", "/en/static/1b1b3a1aefc01997ebf6db60098c82b2/c0255/INSTAR_Cloud_Alexa_Skill_FullHD_02.png 920w", "/en/static/1b1b3a1aefc01997ebf6db60098c82b2/58fee/INSTAR_Cloud_Alexa_Skill_FullHD_02.png 1051w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1b1b3a1aefc01997ebf6db60098c82b2/c0255/INSTAR_Cloud_Alexa_Skill_FullHD_02.png",
              "alt": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "title": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the screenshot above you can see the menu 'Network / Remote Access' of the Full HD camera series. In case of a HD camera you can find the P2P UID under 'Network - P2P'. Please note here that the UID must start with 'INSTAR-' for it to work. In the example below there is another P2P address that does not meet this requirement. In this case please contact the `}<OutboundLink href="https://www.instar.de/contact_us/" mdxType="OutboundLink">{`INSTAR Support via Email`}</OutboundLink>{` (`}<a parentName="p" {...{
        "href": "mailto:support@instar.com"
      }}>{`support@instar.com`}</a>{`):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ba0d418ae5d668646bcd98eb9881f4c0/eb390/INSTAR_Cloud_Alexa_Skill_HD_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7CAAAOwgEVKEqAAAAC8klEQVQozz2SS09bVxSF789ppaqq1EHaiqipAGMMNvhisCEY4rZECiqhqdMKv6CE2MggBryEUmEmjZiGCs9g0mTWqDiVarB9/QA/MNfGwtgO0y86R0mutLS3jnS/s89eS/H5/ajqAKNOJ263m4WFBaLRKPsvXhDd2+OvvT2i+/scHBxIHR4e8vLlK5aXl5mensYfCDAzM8OMx4PP50OZnZ1FKBgMMT5+j0ePfqZQLJI5PSWdy/J/MkO2UKLRbHB9fU29Xufm5oZE4gTX92OoNiu9/T0Y+0wMj4+ieDweeUMwGGTynoufJh+Q1zS02BvO4nFK2TyX+XOqpRK6XuHi4oJarUYymcQ5PoqhuwODxUinxYht1I6ysbHB2toaW1tbTLju4/E+5eLNf+h/v6L++l9asRjNf15TiR9TKpcpFAoSGo/HGRoepMPUgUk1Y1Qt2MdGULa3t9nd3eXP588Zuetk+uFDLms19MtLTvN5tGwWLZMlncmQyWTI5U65urpC01LYhm10mrvotfVjHLBiF0/+49kzdnZ2iEQiDA0NMvlggoquc12vy2nK5TJvWy1arRa6rlMsFmWfSCQwW80Y+3oxWfvoHlBxuMZQAoGAdCq0uMig3YFzzEmlUpHLFzBhwIdPgEqlkqzHx8e0G9qxqIPc+uYrbrV9Tf/QAIr3vd3z808Ycbr48f6EnEQAz8/PaTabH4HCZTG1AJ6cnHD72zaMPWba7tzmiy8/x2DsRPF6vQT8fp48DWJXrYQdKlVd56pel9M0Go2PQNF/eLIAdpm6MKsqhm6jBHZ1G1D8fj9zc3MyNjariV9+uEu1WpU/Czez2awEC5DoxTparbekUkkcDis95k4sqsqnn33Cd+13UFZWVgiHw4gq0r64FObs7ExOIBYvdHR0xFEsJrMnzlOplKy/z8/x62+P8fh8TE1N4X7sRllaWiIUCkng6uoqO5GIjIdQOp2WEgYIiTNN08jlcjKHIrsiw+vr62xubiIy/Q4wFL/LCKsKvgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ba0d418ae5d668646bcd98eb9881f4c0/e4706/INSTAR_Cloud_Alexa_Skill_HD_02.avif 230w", "/en/static/ba0d418ae5d668646bcd98eb9881f4c0/d1af7/INSTAR_Cloud_Alexa_Skill_HD_02.avif 460w", "/en/static/ba0d418ae5d668646bcd98eb9881f4c0/7f308/INSTAR_Cloud_Alexa_Skill_HD_02.avif 920w", "/en/static/ba0d418ae5d668646bcd98eb9881f4c0/f3790/INSTAR_Cloud_Alexa_Skill_HD_02.avif 935w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ba0d418ae5d668646bcd98eb9881f4c0/a0b58/INSTAR_Cloud_Alexa_Skill_HD_02.webp 230w", "/en/static/ba0d418ae5d668646bcd98eb9881f4c0/bc10c/INSTAR_Cloud_Alexa_Skill_HD_02.webp 460w", "/en/static/ba0d418ae5d668646bcd98eb9881f4c0/966d8/INSTAR_Cloud_Alexa_Skill_HD_02.webp 920w", "/en/static/ba0d418ae5d668646bcd98eb9881f4c0/c7dd1/INSTAR_Cloud_Alexa_Skill_HD_02.webp 935w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ba0d418ae5d668646bcd98eb9881f4c0/81c8e/INSTAR_Cloud_Alexa_Skill_HD_02.png 230w", "/en/static/ba0d418ae5d668646bcd98eb9881f4c0/08a84/INSTAR_Cloud_Alexa_Skill_HD_02.png 460w", "/en/static/ba0d418ae5d668646bcd98eb9881f4c0/c0255/INSTAR_Cloud_Alexa_Skill_HD_02.png 920w", "/en/static/ba0d418ae5d668646bcd98eb9881f4c0/eb390/INSTAR_Cloud_Alexa_Skill_HD_02.png 935w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ba0d418ae5d668646bcd98eb9881f4c0/c0255/INSTAR_Cloud_Alexa_Skill_HD_02.png",
              "alt": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "title": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Write down your P2P information and switch back to the INSTAR Cloud. There you had previously pressed `}<strong parentName="p">{`Edit`}</strong>{` in the `}<strong parentName="p">{`Cameras`}</strong>{` tab for the camera. Scroll down in this input mask until you see `}<strong parentName="p">{`P2P Information`}</strong>{` and enter the P2P UI of your camera and your administrator login to the camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1690ff420142a4a54bde0e8e6019893a/024d6/INSTAR_Cloud_Alexa_Skill_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.30434782608696%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACNUlEQVQoz4WSTU8TURSGhw1Lwl528gvUwIKNlKAx2hAW7FAgISQQAsTadsPnBvgJxK3GllISE2P8CRpAJEriRspX5aOdTqfDdL7vfcxMEXXlSZ6899zkvvfce46ysLDA4uJixNLSUkS4TiaTbG5uYlkWxWKRiqqiaRqqWkEIgZSyAfyDkkgkGB4eZmRkhImJCaanp5mfnyfcDw1rtRqFQgHDMLBtG8d2IsMbfBfh2QjPQooA5Uk8jqI00dzcTFOTQlvbrch4aOgZmUwGIQKq1SpmvY6gETcVyQC5n0V+XkPuvkRWD1EGnw5y5+49Yj293O+O0d3zkHQqFVW6vr6O5/sYtRp1Xcc1TfA8pOsif2td/0PgoYyPj9Mdi9Hf30883sejx33MzMwwOjrKm0wGo6pz+vETl1/2sA8KBCen+Mcn+KGGnP7EL57hHx4TlMooAwMD0XNbW1tpaWmhvf02U1NTjI2NkcvlsB2HytER1vk5QtPwy2oD9VpL5QYXlwSmiRI2pKOjg66uLjo7O+ntfUAqlWJycpJsNot1ZXJlGAS+HyGCAMIuC0EgZfSvwTVCSpRwTFZXV1lZWYl0eXmZubk5nr9IkHv1mvruHhdb26g7u/g/DjC+fqO0vUNlawe/VEI3ajjv3iPPzqOGKeHh2dnZG8I8vCSdTpPf2ACzjlOp4Goa0rjC13WcitbIXQ/L86mXNWzbxRXXhuHc/U042Klkinw+z//iw7HN2nebtX2TtwcWvwCYlH2xdEhwMAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1690ff420142a4a54bde0e8e6019893a/e4706/INSTAR_Cloud_Alexa_Skill_03.avif 230w", "/en/static/1690ff420142a4a54bde0e8e6019893a/d1af7/INSTAR_Cloud_Alexa_Skill_03.avif 460w", "/en/static/1690ff420142a4a54bde0e8e6019893a/7f308/INSTAR_Cloud_Alexa_Skill_03.avif 920w", "/en/static/1690ff420142a4a54bde0e8e6019893a/7733b/INSTAR_Cloud_Alexa_Skill_03.avif 961w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1690ff420142a4a54bde0e8e6019893a/a0b58/INSTAR_Cloud_Alexa_Skill_03.webp 230w", "/en/static/1690ff420142a4a54bde0e8e6019893a/bc10c/INSTAR_Cloud_Alexa_Skill_03.webp 460w", "/en/static/1690ff420142a4a54bde0e8e6019893a/966d8/INSTAR_Cloud_Alexa_Skill_03.webp 920w", "/en/static/1690ff420142a4a54bde0e8e6019893a/85db8/INSTAR_Cloud_Alexa_Skill_03.webp 961w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1690ff420142a4a54bde0e8e6019893a/81c8e/INSTAR_Cloud_Alexa_Skill_03.png 230w", "/en/static/1690ff420142a4a54bde0e8e6019893a/08a84/INSTAR_Cloud_Alexa_Skill_03.png 460w", "/en/static/1690ff420142a4a54bde0e8e6019893a/c0255/INSTAR_Cloud_Alexa_Skill_03.png 920w", "/en/static/1690ff420142a4a54bde0e8e6019893a/024d6/INSTAR_Cloud_Alexa_Skill_03.png 961w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1690ff420142a4a54bde0e8e6019893a/c0255/INSTAR_Cloud_Alexa_Skill_03.png",
              "alt": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "title": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`By activating the service (IFTTT or Alexa) you give your consent that the INSTAR Cloud Platform may access your camera via P2P. Then click on Test to check the settings. If everything is entered correctly, you should see a `}<strong parentName="p">{`Connection successful`}</strong>{`.`}</p>
    <h3 {...{
      "id": "alternatively-via-the-web-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alternatively-via-the-web-interface",
        "aria-label": "alternatively via the web interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alternatively via the Web Interface`}</h3>
    <p>{`Permission for P2P access can also be granted and revoked - `}<strong parentName="p">{`only Full HD cameras`}</strong>{`- via the WebUI. This way is available if the camera has already been added to the INSTAR Cloud.`}</p>
    <p>{`In the web interface of your FullHD camera go to `}<strong parentName="p">{`Smarthome`}</strong>{` in the settings and there to `}<strong parentName="p">{`Alexa`}</strong>{`. As shown in the screenshot below, you can allow access via Alexa there. After clicking on `}<strong parentName="p">{`Apply`}</strong>{` you will be asked if you want to enable P2P access in the cloud, please confirm this so that access via Alexa works.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/83d8f03821eea1448e94e5e84f7e05bb/eb390/INSTAR_Cloud_Alexa_Skill_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.39130434782609%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABw0lEQVQozz2R22sTQRSH908Uq+izoCA+iOzOnJktVLSXXBtrEQRfRN/EB/ssiIrUGJNiEsHSGm9tkt1Ns7szO9mbye7IbKQ/zsNw4JvvHI6mI0AYMBBKCBCyapLrt27f2Hlz7XHvcr11sdJcqX1aqbUu1VvqUW1eKO9fqX+8+aR39e4LTccK1hEQQlUIMTDqHJ78ducDOx448cBJvtvR0UgcW+HAiX9M0mM7GQv5er+rGZggAARA/7OEUrCsURzNBPdDwQX3Bfe57wbMmwVMBIwHXErZ7fc1HYGBAWOyDAAQgk+Hp2IWeT7zGWeMu54/OZtOXZdxrooxKeXnzhdtubOBFWWatPgBj0bDOE6EELMinPNAiECohggCZzJNF7Ld7mgmpYaCCRBqFmMDIGs8zrIsKZKmaZIkWZZJKfNcKudJ9Pwget8s4DuGMp/vrGBrLKVcLBZZkTzPonT+axL+PEs82xWHR3//DHvtlmaaFAHBxcxmocbIcBy78OTntqmY7x14r76G3z70/afPwpd73XdvteWdCjlBGBAQXdfX79+rVMpbmxulzfXS1ka1UtqulXYb5d1GufGgWt3Zbjx6uLa2+g9Vhn3qGAtMJAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/83d8f03821eea1448e94e5e84f7e05bb/e4706/INSTAR_Cloud_Alexa_Skill_04.avif 230w", "/en/static/83d8f03821eea1448e94e5e84f7e05bb/d1af7/INSTAR_Cloud_Alexa_Skill_04.avif 460w", "/en/static/83d8f03821eea1448e94e5e84f7e05bb/7f308/INSTAR_Cloud_Alexa_Skill_04.avif 920w", "/en/static/83d8f03821eea1448e94e5e84f7e05bb/f3790/INSTAR_Cloud_Alexa_Skill_04.avif 935w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/83d8f03821eea1448e94e5e84f7e05bb/a0b58/INSTAR_Cloud_Alexa_Skill_04.webp 230w", "/en/static/83d8f03821eea1448e94e5e84f7e05bb/bc10c/INSTAR_Cloud_Alexa_Skill_04.webp 460w", "/en/static/83d8f03821eea1448e94e5e84f7e05bb/966d8/INSTAR_Cloud_Alexa_Skill_04.webp 920w", "/en/static/83d8f03821eea1448e94e5e84f7e05bb/c7dd1/INSTAR_Cloud_Alexa_Skill_04.webp 935w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/83d8f03821eea1448e94e5e84f7e05bb/81c8e/INSTAR_Cloud_Alexa_Skill_04.png 230w", "/en/static/83d8f03821eea1448e94e5e84f7e05bb/08a84/INSTAR_Cloud_Alexa_Skill_04.png 460w", "/en/static/83d8f03821eea1448e94e5e84f7e05bb/c0255/INSTAR_Cloud_Alexa_Skill_04.png 920w", "/en/static/83d8f03821eea1448e94e5e84f7e05bb/eb390/INSTAR_Cloud_Alexa_Skill_04.png 935w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/83d8f03821eea1448e94e5e84f7e05bb/c0255/INSTAR_Cloud_Alexa_Skill_04.png",
              "alt": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "title": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you receive the following error message, you must first add your camera to the INSTAR Cloud (see first step above):`}</p>
    <blockquote>
      <p parentName="blockquote">{`To set this setting, the camera must be logged in to your free cloud account!`}</p>
    </blockquote>
    <br /><br />
    <h2 {...{
      "id": "link-to-your-instar-cloud-account",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#link-to-your-instar-cloud-account",
        "aria-label": "link to your instar cloud account permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Link to your INSTAR Cloud account`}</h2>
    <p>{`Link your INSTARStart the Alexa App on your smartphone and install the INSTAR Cloud Skill. During activation you will be asked for the login for your INSTAR Cloud Account. Enter your data to link your account with Alexa: Cloud Account`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a0bef484268ecc44ebf5a7a315e0e75f/302a4/INSTAR_Cloud_Alexa_App_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC0UlEQVQ4y42T32scVRTH59XXSuuf4JsFLZb2LxChSrKBEqRQrLZN0hZfRIwg/uiDPqStgVofUhVaikUa9EnwoX2IrUrENNo00DSbTTK7s7M7M7s7d+fOnV/3I3O3WYz0wTN8OOde7vnec+7ca707M8c7n33Fh7Pfcv7KdaYvXmX6wlU+uPg1U5/MMvHRF7w/M8enX15j+sIcH1++xlvn3mP82JucOH2WN46f5EhlnCOj44wcPYYVtTxUP+Jp5guJLyKESvi/ZnWXlomcJirLkXFMJGNkrFCxJG2uI4OANAwhiUHFkCiKojDJjuOwtLTE4uIiWmszb/WzjFBK0Jo8z1FKUaARjsvbn1/n1KVbnJmdZ+LSLROfnbmJX3cIRUitVjOinU7H5KVpipXN/4D3x32qrYC238HvhhSluEpYXrP5bWWDhfuPDPf+Xmf50Ta9IKDteXhBQJKmpFmOShJ63R5WUXdQzS3C0CfNCrI8N+VroMgzMNFuC4WAIoVcgd5Zo81nDVfpYldS2X7NdqhuN3BavsF22oSRJJGSH+885Jvba3z/S5UbC1Vu3FkjcAMsXRTDiozXepfgw8c1w+r6Jms1GxFJdJ6zvGJz70GDP1cb/L7S4Ne/bPpdMahwR2hHtLTyj3WjGC+MSJLUHPigE23iXEXkUY9MhqhegPBd0jTBKhP/izZJmanw8WbdtF3G61t1vKBLlmWIfp+g06XV9nCaLm6rjZTyX2f4FKu7bWynRcP12Gq4hlglaLOxNpRHU25QVm0EnXZAo+XTaHmGuuuZ8XazzYOqzeqGzWa9yYbtDBF9SZ5npsXy/kVRhBDCYL1UmeTF0QmGfnSSF147yf7XTz2Zm+RAZWrA2BTPv3Kc7366i0jACWKECI1okiTEcYy15+UR9h6qsO/w2MAfqvDswRHDnoMjZrz3Cc8dHuOZ/a8y//PC4D5GathySfmW/wFiWdave8JZeQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a0bef484268ecc44ebf5a7a315e0e75f/e4706/INSTAR_Cloud_Alexa_App_01.avif 230w", "/en/static/a0bef484268ecc44ebf5a7a315e0e75f/d1af7/INSTAR_Cloud_Alexa_App_01.avif 460w", "/en/static/a0bef484268ecc44ebf5a7a315e0e75f/7f308/INSTAR_Cloud_Alexa_App_01.avif 920w", "/en/static/a0bef484268ecc44ebf5a7a315e0e75f/d8ca6/INSTAR_Cloud_Alexa_App_01.avif 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a0bef484268ecc44ebf5a7a315e0e75f/a0b58/INSTAR_Cloud_Alexa_App_01.webp 230w", "/en/static/a0bef484268ecc44ebf5a7a315e0e75f/bc10c/INSTAR_Cloud_Alexa_App_01.webp 460w", "/en/static/a0bef484268ecc44ebf5a7a315e0e75f/966d8/INSTAR_Cloud_Alexa_App_01.webp 920w", "/en/static/a0bef484268ecc44ebf5a7a315e0e75f/260c2/INSTAR_Cloud_Alexa_App_01.webp 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a0bef484268ecc44ebf5a7a315e0e75f/81c8e/INSTAR_Cloud_Alexa_App_01.png 230w", "/en/static/a0bef484268ecc44ebf5a7a315e0e75f/08a84/INSTAR_Cloud_Alexa_App_01.png 460w", "/en/static/a0bef484268ecc44ebf5a7a315e0e75f/c0255/INSTAR_Cloud_Alexa_App_01.png 920w", "/en/static/a0bef484268ecc44ebf5a7a315e0e75f/302a4/INSTAR_Cloud_Alexa_App_01.png 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a0bef484268ecc44ebf5a7a315e0e75f/c0255/INSTAR_Cloud_Alexa_App_01.png",
              "alt": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "title": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "adding-your-instar-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adding-your-instar-camera",
        "aria-label": "adding your instar camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding your INSTAR camera`}</h2>
    <p>{`You can use Auto Search to search for compatible devices. All cameras that you have previously unlocked in the INSTAR Cloud should be displayed as available:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9eaf9b4b53f6930ff6e404f08d1093a8/302a4/INSTAR_Cloud_Alexa_App_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC5klEQVQ4y32TaUhUURiG519Ev0pbtD20qCjLSk3HnDQsKzJ1cmYaSytMqGyZAn9EGOSCURRtSEELLWjaQoWmWEIUaYER1I82Z8ottxm3xnHGO0+cM42JiB+8nHMv9zznPd95ryrrzDWOn7tOzpU7UtkXbpJ56iLlr94hyuEcxDmoyNFbqVkFjA/ayIwoPXOjjUxfo8MnNBH/SB0qxqhvlkbqG5r5bmnkm7kBq61bvtcdPsWEZZuZpt6Gb1gik1cnMSVc6wEqintUmMvlwmKux2Ix09LSLNXU1ISiKBiO5jJuSRy+q5OYFJogNTFkqwdo73fQ2m6l3dpFd08fnbZubF09cqHdbpdS3G6Gb6s35TA1QsviTbuZvz6VBRvSCIxNlS1QDTidfDU3UFP3ieq3ddR8+Cw3GFluAXW7/wFzmRSSwKqkfaxLO0ZY8gHmxqR4gE6nCwHtsHbxu61TOhsOGKkhh+FagrdmsDx+r1Rg7E4P0NbdS1unTR63p/cPogX9joGhxcNH79xgymV6pI6gLelE6DJZFr+XRXG7mKUxoPrV3Cpv8cfPRppb22lp66CtwzomUDgctzSOyeFafEITZGREC/wik1E5Bpz0OzyuRuuZV8oIhyI2fupkeXQhERuRR5X4RiRHfDqouHEpCoOKMgz2fxPl34PuiMehgPiEJUqJ6EwTDscKtqQpCn29fdj/2OVc/inH8pkSksi86B3M0RilZmuMBKw1oip+9oasGxXk3XtBQVE12berOHmrEvPn7zyprqPkeS3Fz15TUvaG+xW1lL98z+ETF1hiOIE6I5/Y/afRZOQTvCcPtdaE6nHZa87ereRq6UuuP6zmYlEVl4qr+PLuIwcvPyb9/ANMhU8xFT5h17kHZF95xG5TDrPCEli0djsLovQs1BgIVG8jQMQmQp9J7M4jxKQcIjrlEHFpR1kZn87Zm6XeznnPP9QJnbiUFfHMjDbiF6XHP0rP1Egdfmv0/AXPl0wKQomHkwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9eaf9b4b53f6930ff6e404f08d1093a8/e4706/INSTAR_Cloud_Alexa_App_02.avif 230w", "/en/static/9eaf9b4b53f6930ff6e404f08d1093a8/d1af7/INSTAR_Cloud_Alexa_App_02.avif 460w", "/en/static/9eaf9b4b53f6930ff6e404f08d1093a8/7f308/INSTAR_Cloud_Alexa_App_02.avif 920w", "/en/static/9eaf9b4b53f6930ff6e404f08d1093a8/d8ca6/INSTAR_Cloud_Alexa_App_02.avif 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9eaf9b4b53f6930ff6e404f08d1093a8/a0b58/INSTAR_Cloud_Alexa_App_02.webp 230w", "/en/static/9eaf9b4b53f6930ff6e404f08d1093a8/bc10c/INSTAR_Cloud_Alexa_App_02.webp 460w", "/en/static/9eaf9b4b53f6930ff6e404f08d1093a8/966d8/INSTAR_Cloud_Alexa_App_02.webp 920w", "/en/static/9eaf9b4b53f6930ff6e404f08d1093a8/260c2/INSTAR_Cloud_Alexa_App_02.webp 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9eaf9b4b53f6930ff6e404f08d1093a8/81c8e/INSTAR_Cloud_Alexa_App_02.png 230w", "/en/static/9eaf9b4b53f6930ff6e404f08d1093a8/08a84/INSTAR_Cloud_Alexa_App_02.png 460w", "/en/static/9eaf9b4b53f6930ff6e404f08d1093a8/c0255/INSTAR_Cloud_Alexa_App_02.png 920w", "/en/static/9eaf9b4b53f6930ff6e404f08d1093a8/302a4/INSTAR_Cloud_Alexa_App_02.png 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9eaf9b4b53f6930ff6e404f08d1093a8/c0255/INSTAR_Cloud_Alexa_App_02.png",
              "alt": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "title": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select the camera you want to add and click on `}<strong parentName="p">{`Device Setup`}</strong>{`. Adding the camera to a group as shown in the screenshot below is optional and can be skipped:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/96f1df94131d8b5989fbd34ab767e2a4/302a4/INSTAR_Cloud_Alexa_App_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACoklEQVQ4y3WT7XPSWBTG8y9YyoK0lsBuW+yM2yq2CQRyhSK0umMDSUgIBVrKyuJbrfVlxg/qqB3Hv/rn3EtxsbP74ZlzcnLOc895zr2aXu2SKrfJVLusNQZcL7W5tu3wW9EjafkkS21+r/XI1g5VbLHgTq3ZYtkOWW8MSFci9R0vuGjF4DnV/jmF9tRuOWNFIJNE9wwrfIHhP+WuO1EF8UvC/4NWDJ7ROH5DtfeKveFb8s0xN/eP0CsR9eM37A3fUY5Oye0dsSzCn4VXyWe+tvHwbzadCXn3KVvOhC3nH9b3T7hxr8sdZ8yOOyHvjNl4cMytv0ZKElmcsHwSRY8Fo8mi6f4k1bIPH5PeH7FUH5KqHZGo9EhWByRFV3W0cq+jrNRO6jTTUcZKnVPE4Rm7g9es1vvEzBZaruyTKbZY2TkgbTikzSa64ZAyW8TMKcmsg/nx5CJlx3dbE7bdCXo1UgdpDdvltuXy584jNo0DbhkH5HcesWEcECt6aqyrwstCw3tCrX+OiE7Z7b1CRC9V11pOBOgFV3WWNlsKutkkJUWXBP+xVdlhZrfLWr2v8Mf9vrpWUlctaQfELZ9FefIcZmTxSz9+ZZtSiphxCSVLS+VrMfPfwLz95b7J5MuFzEae5c5DLSVTDtFF9AuyIiJl+SzIpILLgugoKF++kFKbbLWHPkNlajOig2a5Z2wff6b2+Cv3xxfYoy+Y/Y9sVnuk7ZBs5ZBMY6gg/ZVywFZ9iHlyQX74lcrkO+bogjujb1jeOdpqJWJNhKzOQbc8bpZ8zM57rP4HGief2Dv5hDX4SCF4y4YIyImQ9XKbdbtNzg5YK/noRRdNLmRJdLhuh6TEFAn5GuSVMZosW57CkuVxo+SRMJvTf+WAlKwTHWWTdshi0ecH2EHnumk6MusAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/96f1df94131d8b5989fbd34ab767e2a4/e4706/INSTAR_Cloud_Alexa_App_03.avif 230w", "/en/static/96f1df94131d8b5989fbd34ab767e2a4/d1af7/INSTAR_Cloud_Alexa_App_03.avif 460w", "/en/static/96f1df94131d8b5989fbd34ab767e2a4/7f308/INSTAR_Cloud_Alexa_App_03.avif 920w", "/en/static/96f1df94131d8b5989fbd34ab767e2a4/d8ca6/INSTAR_Cloud_Alexa_App_03.avif 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/96f1df94131d8b5989fbd34ab767e2a4/a0b58/INSTAR_Cloud_Alexa_App_03.webp 230w", "/en/static/96f1df94131d8b5989fbd34ab767e2a4/bc10c/INSTAR_Cloud_Alexa_App_03.webp 460w", "/en/static/96f1df94131d8b5989fbd34ab767e2a4/966d8/INSTAR_Cloud_Alexa_App_03.webp 920w", "/en/static/96f1df94131d8b5989fbd34ab767e2a4/260c2/INSTAR_Cloud_Alexa_App_03.webp 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/96f1df94131d8b5989fbd34ab767e2a4/81c8e/INSTAR_Cloud_Alexa_App_03.png 230w", "/en/static/96f1df94131d8b5989fbd34ab767e2a4/08a84/INSTAR_Cloud_Alexa_App_03.png 460w", "/en/static/96f1df94131d8b5989fbd34ab767e2a4/c0255/INSTAR_Cloud_Alexa_App_03.png 920w", "/en/static/96f1df94131d8b5989fbd34ab767e2a4/302a4/INSTAR_Cloud_Alexa_App_03.png 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/96f1df94131d8b5989fbd34ab767e2a4/c0255/INSTAR_Cloud_Alexa_App_03.png",
              "alt": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "title": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The camera will be set up for you automatically and will be available to you shortly afterwards. You can then check that the camera is also activated:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/08cedff069a90df9c00b04351b71a0d6/302a4/INSTAR_Cloud_Alexa_App_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACHElEQVQ4y3WUWW/aQBSF5y8Uswhj9kahhT4gGrAxeCVR1Aql3rDB4CS0lRKpqhpaVe1zG6n/+lQzxi2L8/BpxrOcuXPvGZOc5IDSMJc4ubgGP/bBiTbyQxcF2WX9shpAUHxwkgNu7EPQFqgbISraHDUjRFmdIyvaTIfQjbsUhi6yQxfNwTt0Rz5ejQO8EB1Ge+ih07fQMUJ0/C/g9RDcwEJOcpGXPQZJItwVzUgOXooWxPAn2sEPNK8ecGJt0LQ2aE0/4/UkQmN6j8blR5TP13uQguyBwg1sZNhpDouwLtnoT67Rm9yga0QxZoSutsSpGqBxHrFUHN6QlLU5xNknqOEDjOgr9NUG7TdrZEczZMwInL4EZ65ijCU40YKgzXF6eYOSEhzdjtBkCmrAhC7W39Gz71iCD09OoAKCOgevJNF5jBxr3bgodBG9bqZv/atwPO4eRZAIFsd+XIxtIXk6L8/ioqRFkdanZCWH5Y7aJi85eEYrfzaFJV4h9/b+2DZPCT95GHXJTp9wks2ueUh2uzFNNJXtOlJTAtS0cI+6FqIke6n5S/Pt7sFE9r6h+/4R0odH6Hd/0Lv9DfH2F9pmhMy2QGki++IJLkhLX6ClBsysSftcdlFRfFTNFSraAlVjiSp9u3rIHkFVD9kY/a5s+9SbtPIkJ3vgqQ2UAEU1AE/dP5qxRNPN1KOUkuJDUP6/jOJ4Fs8pMfSnQiP8Cwrp2dwYvdn/AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/08cedff069a90df9c00b04351b71a0d6/e4706/INSTAR_Cloud_Alexa_App_04.avif 230w", "/en/static/08cedff069a90df9c00b04351b71a0d6/d1af7/INSTAR_Cloud_Alexa_App_04.avif 460w", "/en/static/08cedff069a90df9c00b04351b71a0d6/7f308/INSTAR_Cloud_Alexa_App_04.avif 920w", "/en/static/08cedff069a90df9c00b04351b71a0d6/d8ca6/INSTAR_Cloud_Alexa_App_04.avif 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/08cedff069a90df9c00b04351b71a0d6/a0b58/INSTAR_Cloud_Alexa_App_04.webp 230w", "/en/static/08cedff069a90df9c00b04351b71a0d6/bc10c/INSTAR_Cloud_Alexa_App_04.webp 460w", "/en/static/08cedff069a90df9c00b04351b71a0d6/966d8/INSTAR_Cloud_Alexa_App_04.webp 920w", "/en/static/08cedff069a90df9c00b04351b71a0d6/260c2/INSTAR_Cloud_Alexa_App_04.webp 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/08cedff069a90df9c00b04351b71a0d6/81c8e/INSTAR_Cloud_Alexa_App_04.png 230w", "/en/static/08cedff069a90df9c00b04351b71a0d6/08a84/INSTAR_Cloud_Alexa_App_04.png 460w", "/en/static/08cedff069a90df9c00b04351b71a0d6/c0255/INSTAR_Cloud_Alexa_App_04.png 920w", "/en/static/08cedff069a90df9c00b04351b71a0d6/302a4/INSTAR_Cloud_Alexa_App_04.png 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/08cedff069a90df9c00b04351b71a0d6/c0255/INSTAR_Cloud_Alexa_App_04.png",
              "alt": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "title": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you look at the Device Overview, all cameras are displayed twice - once as IP cameras and once as security system. You need the latter if you want to be able to disable the motion detection of your camera by voice command:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1dea0cb8061599ce268b02d771284577/302a4/INSTAR_Cloud_Alexa_App_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC9klEQVQ4y1WUV3MaWRSE52HttdeAAjkOIJGEQRJiCDMghMC2ZIIIMwxhJFaW197ah/3/b9/WvbLCPnSdCVV9+5w+fZVQa4FPNwmf26g9h2DLYr8xJ2RYHPRviV+sULtriYBu4mua7GY0XKEUrsgh7kiGD+ED3vqT8lkJNeccdJbkeg4xQdyYEW3OEQeVrH/JjH6yX5/hbcwJGBYBY8FevoE3XcQVzfK7P8n7YFrCE8uhnGojjjs2x22TfGNCrjHlY21MuWWRGvzN4fVfZAY/SX55INb7k1j/G96jFu5olp3EEZ54AVc0zx+RLLtqEaVozPFdOnjbNju6hcd4HEFBn1Gc/sPx7BGF0Q/CbZtY12E/V8er5vEmj/Cli+wm8rLtvUQBxfx6R6u3ptKcUKqNKdVvqGpDThoTQoMfJPp3JHq3RC/WBA2LcGdNpKQzHI2ZWTZXgyFfvg4YDEfkjzWU2ukV1ZbJmT6lrI0kqSA8bdwQ692SvFgR1E38zbk0RRgWK+ksFxbb7R32YoFpmkzGI4qnGspZf0Pk6h7/xRq3buE2FuzrJjl9Rvb6m3RaKBNkTy7Hygab9YqHh+/c39/jOLd86vc5OtFQrP6KbsdCr4+paUM0bUS7OqBaH5P4vCXVc57JhFKfbhEvG5izCUvbZrlesXYcJpMxHys1lOvrLaW+Q9aYk25MSTVn5OoTys0p4e7mcVV+EUqF9RmBQp1C6YRC+YxipUax2qBU0VBzZZRCfUJADLxt4xUzMizEbhaaU5KftkTPl/hfEQY/f2cno/E+kOStP8Ubr8obb4LffElcwuVybUytu6SszyjUbhAHCGPEPkZfGSIhCR8k4Yfgy0JLhA7wiD0MthbEOks5+Ofhy0S8tBo0XhQGmnPc6Qrv/Or/CN8FknhE9NRLh3h3g6iJX1W0KfD6m3q5Qe1uCLeX7GVFltO4IhmJpzzL6IlLIHpuS5VPNdxayMsh1LLk+/O/c5uQSNRhVRLuxHN4YllZ3dGMrP8BsIDGPhNKxI4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1dea0cb8061599ce268b02d771284577/e4706/INSTAR_Cloud_Alexa_App_05.avif 230w", "/en/static/1dea0cb8061599ce268b02d771284577/d1af7/INSTAR_Cloud_Alexa_App_05.avif 460w", "/en/static/1dea0cb8061599ce268b02d771284577/7f308/INSTAR_Cloud_Alexa_App_05.avif 920w", "/en/static/1dea0cb8061599ce268b02d771284577/d8ca6/INSTAR_Cloud_Alexa_App_05.avif 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1dea0cb8061599ce268b02d771284577/a0b58/INSTAR_Cloud_Alexa_App_05.webp 230w", "/en/static/1dea0cb8061599ce268b02d771284577/bc10c/INSTAR_Cloud_Alexa_App_05.webp 460w", "/en/static/1dea0cb8061599ce268b02d771284577/966d8/INSTAR_Cloud_Alexa_App_05.webp 920w", "/en/static/1dea0cb8061599ce268b02d771284577/260c2/INSTAR_Cloud_Alexa_App_05.webp 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1dea0cb8061599ce268b02d771284577/81c8e/INSTAR_Cloud_Alexa_App_05.png 230w", "/en/static/1dea0cb8061599ce268b02d771284577/08a84/INSTAR_Cloud_Alexa_App_05.png 460w", "/en/static/1dea0cb8061599ce268b02d771284577/c0255/INSTAR_Cloud_Alexa_App_05.png 920w", "/en/static/1dea0cb8061599ce268b02d771284577/302a4/INSTAR_Cloud_Alexa_App_05.png 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1dea0cb8061599ce268b02d771284577/c0255/INSTAR_Cloud_Alexa_App_05.png",
              "alt": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "title": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`Disabling motion detection will disable the alarm function of your camera. This means that no more alarms are triggered and no more alarm recordings or alarm notifications are made.`}</p>
    </blockquote>
    <p>{`Activate the function `}<strong parentName="p">{`Disable voice command`}</strong>{` and enter a PIN code:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b01ce7a257b8f6a1296d8ad09e2c4363/302a4/INSTAR_Cloud_Alexa_App_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACdklEQVQ4y31T624SQRjdRxAKbFsupbSlNbExMSks7LLALrQgGmFhL7AL9GJL71VfyDfwt89i0mi1jTExvsIx38dusf3hj5PJzHxz5sz5zgjLuotkpY+l6gDLmosFxcJc3sC8bPL6YsnG2vYIq/URYsUeIlIXMdlEVDYRky1GtGgyaF3YeHOMTfMSc4UewnmDD1HRvGLxBQS6jDCvmNP9YhfRQhfJsoPV+pBrgjVhtTXByssjrLUmyDQOkazvI1kdIlF2kdYGSFcHSGsuK52T/At9EMG/c4Lw9NUhnhun2Gwf41nnBEnVRuj1KWKNA6zXh9ho7iG7PcZKzUNctRErPHzyw9GEEG8cINXYR0r3kKqNIEoGQq0TLDTfQrHOUPHeQx1codS/hGSesVJSRh4HIykTZRMiKUyozn3R9AkmolIX8ZKNgnXGkO1zKM4FE2dqHtctlCxuUFx1mIiaSRCog9mdMU+ivg8R1UG8MkB5cAVt+AFq/wqyfYGK+45rqYaeT4QkhuZ0AROm9SF3kAkLvtmSwQqL1jnyvVPkuif83BftCROJ/yNcqY+w3tybEgXd8z1ZLFkcFSqkkfwiElHuMVFEMjirwZwgpDQX8XKfCci/KWaxeIxgj0jYS8WarVFTUlUXi2XnYZ78CFBYH+csIA0I2a6SNSPM6N69H1P/OhCbRxD1MWKSwb4+VkcBD+faCBd6eKKPEVJshLfaCOc7flM0D6R0SXN5JC8oDmndQ5K+nebd79GXzNSGyDZ2uePZnV0fY4ZA3iXKDqskEsolNUAsmgj2GKrDfzeUa+Pjp8/49fsPvnz9gevvd7i+ucX1zR2+3f7EXzlm6iyIOcGFAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b01ce7a257b8f6a1296d8ad09e2c4363/e4706/INSTAR_Cloud_Alexa_App_06.avif 230w", "/en/static/b01ce7a257b8f6a1296d8ad09e2c4363/d1af7/INSTAR_Cloud_Alexa_App_06.avif 460w", "/en/static/b01ce7a257b8f6a1296d8ad09e2c4363/7f308/INSTAR_Cloud_Alexa_App_06.avif 920w", "/en/static/b01ce7a257b8f6a1296d8ad09e2c4363/d8ca6/INSTAR_Cloud_Alexa_App_06.avif 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b01ce7a257b8f6a1296d8ad09e2c4363/a0b58/INSTAR_Cloud_Alexa_App_06.webp 230w", "/en/static/b01ce7a257b8f6a1296d8ad09e2c4363/bc10c/INSTAR_Cloud_Alexa_App_06.webp 460w", "/en/static/b01ce7a257b8f6a1296d8ad09e2c4363/966d8/INSTAR_Cloud_Alexa_App_06.webp 920w", "/en/static/b01ce7a257b8f6a1296d8ad09e2c4363/260c2/INSTAR_Cloud_Alexa_App_06.webp 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b01ce7a257b8f6a1296d8ad09e2c4363/81c8e/INSTAR_Cloud_Alexa_App_06.png 230w", "/en/static/b01ce7a257b8f6a1296d8ad09e2c4363/08a84/INSTAR_Cloud_Alexa_App_06.png 460w", "/en/static/b01ce7a257b8f6a1296d8ad09e2c4363/c0255/INSTAR_Cloud_Alexa_App_06.png 920w", "/en/static/b01ce7a257b8f6a1296d8ad09e2c4363/302a4/INSTAR_Cloud_Alexa_App_06.png 1080w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b01ce7a257b8f6a1296d8ad09e2c4363/c0255/INSTAR_Cloud_Alexa_App_06.png",
              "alt": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "title": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "use-of-instar-skills",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#use-of-instar-skills",
        "aria-label": "use of instar skills permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Use of INSTAR Skills`}</h2>
    <h3 {...{
      "id": "smart-home-skill",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#smart-home-skill",
        "aria-label": "smart home skill permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Smart-Home Skill`}</h3>
    <p>{`With the Smarthome Skill you can now display the live video of your camera on your Alexa device. For the camera named `}<strong parentName="p">{`Dachterasse`}</strong>{` (this is the name you gave to your camera in the INSTAR Cloud) you now only need to say the following:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/808ba4fa17b72c67816b9f8f8c7d761f/29007/INSTAR_Cloud_Alexa_Skill_Example_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACLUlEQVQoz4WRW0tUURiG96101UVCQRRipZIyQUSBlHajKAWlVHRXfyOIuowuFYouYrQDJp4VC2VmHCYPNYxRHphSnFHnsGccnVFr9p6919pPzB4LO8B88PC9613rexespViWxa/ar//28r0Y+VJyhs7u9wxCmH8M/i+0WOXPKZ/n/MzO+bAsiRDiH6SUmKbJeiRKIrnBejRKLK6yHokQTySIRGNE8p6qYpoC5fWbZ4TWgkWulqBnIadhaVkwcpDTwdBtT+paYd+SKGPjQ/ZMOp3G6ezA2dFJT28/A4MjvHzVxfjbd6g5i6GUZFDVGUoadre1qtOXMHihCgZSFktZCyUQ8NuBbreHkpISDh08wJnKEzTW1VJTdRxHRRl9wSgtCybNE2s0uUI2v7U7RGsgQ8MXg96kiTIzPW0Heia8nCwvo+liDbev1nH3VgN3Wuu5UlfDmMdLElj5IQhrFmF9H/m1JlnJSrZNC2XS994OnJr5QPVpB5dq67lx7TI3Wxq53lxL0/mjPG1vK7ylFEV/WvF5vUCOlcVZyk85KD3moKKykrPnLlBdXcWRw6U8uH8PMDC0DCK3u8cOQt8ukPe1DDK7hRL/5oetRYz4J8a62uh58pCR548YdT7G092Of7STneVJ2JxHpuaRW4vI9NcCqQXkxlzB39OKmO9ChF1YiY+QDkDMA6ERCA3DUi8Eu7GC/ciwC7nqRiwNI5eHkeFx5KoLGfEh1RlkbAoZ9fETL2j6v3vS3EYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/808ba4fa17b72c67816b9f8f8c7d761f/e4706/INSTAR_Cloud_Alexa_Skill_Example_02.avif 230w", "/en/static/808ba4fa17b72c67816b9f8f8c7d761f/d1af7/INSTAR_Cloud_Alexa_Skill_Example_02.avif 460w", "/en/static/808ba4fa17b72c67816b9f8f8c7d761f/7f308/INSTAR_Cloud_Alexa_Skill_Example_02.avif 920w", "/en/static/808ba4fa17b72c67816b9f8f8c7d761f/e1c99/INSTAR_Cloud_Alexa_Skill_Example_02.avif 1380w", "/en/static/808ba4fa17b72c67816b9f8f8c7d761f/2babf/INSTAR_Cloud_Alexa_Skill_Example_02.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/808ba4fa17b72c67816b9f8f8c7d761f/a0b58/INSTAR_Cloud_Alexa_Skill_Example_02.webp 230w", "/en/static/808ba4fa17b72c67816b9f8f8c7d761f/bc10c/INSTAR_Cloud_Alexa_Skill_Example_02.webp 460w", "/en/static/808ba4fa17b72c67816b9f8f8c7d761f/966d8/INSTAR_Cloud_Alexa_Skill_Example_02.webp 920w", "/en/static/808ba4fa17b72c67816b9f8f8c7d761f/445df/INSTAR_Cloud_Alexa_Skill_Example_02.webp 1380w", "/en/static/808ba4fa17b72c67816b9f8f8c7d761f/fad48/INSTAR_Cloud_Alexa_Skill_Example_02.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/808ba4fa17b72c67816b9f8f8c7d761f/81c8e/INSTAR_Cloud_Alexa_Skill_Example_02.png 230w", "/en/static/808ba4fa17b72c67816b9f8f8c7d761f/08a84/INSTAR_Cloud_Alexa_Skill_Example_02.png 460w", "/en/static/808ba4fa17b72c67816b9f8f8c7d761f/c0255/INSTAR_Cloud_Alexa_Skill_Example_02.png 920w", "/en/static/808ba4fa17b72c67816b9f8f8c7d761f/b1001/INSTAR_Cloud_Alexa_Skill_Example_02.png 1380w", "/en/static/808ba4fa17b72c67816b9f8f8c7d761f/29007/INSTAR_Cloud_Alexa_Skill_Example_02.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/808ba4fa17b72c67816b9f8f8c7d761f/c0255/INSTAR_Cloud_Alexa_Skill_Example_02.png",
              "alt": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "title": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To deactivate the motion detection, simply type `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`"Alexa, deactivate roof terrace"`}</code>{`. You will then be asked for the 4-digit PIN you entered in the Alexa app above.`}</p>
    <p>{`An overview of the commands can be found under `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Camera_Commands/"
      }}>{`INSTAR Alexa camera commands`}</a>{`. A list of possible error messages can be found in the `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/Troubleshooting/"
      }}>{`Troubleshooting`}</a>{`.`}</p>
    <h3 {...{
      "id": "instar-cloud-skill",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#instar-cloud-skill",
        "aria-label": "instar cloud skill permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR Cloud Skill`}</h3>
    <p>{`To view your alarm recordings in the INSTAR Cloud and adjust your cloud settings, you must start the INSTAR Cloud Skill:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/885b5a3262da457cd3ec3c677af8d01f/b17f8/INSTAR_Cloud_Alexa_Skill_Example_01.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB1mJbFyCP/8QAGhABAQACAwAAAAAAAAAAAAAAAQIAAxESIv/aAAgBAQABBQJ3EvfkHK1zSeTP/8QAFhEAAwAAAAAAAAAAAAAAAAAAAAES/9oACAEDAQE/AZZLP//EABYRAQEBAAAAAAAAAAAAAAAAAAASAf/aAAgBAgEBPwGsU//EABgQAQEBAQEAAAAAAAAAAAAAAAERADEQ/9oACAEBAAY/Ao26g7jqmhzz/8QAGhABAQEBAAMAAAAAAAAAAAAAAREAMSFhwf/aAAgBAQABPyGAlFYYmRH1lT4Y9WydxB8Y4au//9oADAMBAAIAAwAAABAj/wD/xAAXEQADAQAAAAAAAAAAAAAAAAAAITFh/9oACAEDAQE/EG1Gh//EABgRAAIDAAAAAAAAAAAAAAAAAAAhETFR/9oACAECAQE/EF0yGH//xAAcEAEBAQACAwEAAAAAAAAAAAABEQAhQTFhgcH/2gAIAQEAAT8QGzuxTn7pBkIl/uqEm9xoREVRweDhxiSwOt7nf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/885b5a3262da457cd3ec3c677af8d01f/e4706/INSTAR_Cloud_Alexa_Skill_Example_01.avif 230w", "/en/static/885b5a3262da457cd3ec3c677af8d01f/d1af7/INSTAR_Cloud_Alexa_Skill_Example_01.avif 460w", "/en/static/885b5a3262da457cd3ec3c677af8d01f/7f308/INSTAR_Cloud_Alexa_Skill_Example_01.avif 920w", "/en/static/885b5a3262da457cd3ec3c677af8d01f/e1c99/INSTAR_Cloud_Alexa_Skill_Example_01.avif 1380w", "/en/static/885b5a3262da457cd3ec3c677af8d01f/2babf/INSTAR_Cloud_Alexa_Skill_Example_01.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/885b5a3262da457cd3ec3c677af8d01f/a0b58/INSTAR_Cloud_Alexa_Skill_Example_01.webp 230w", "/en/static/885b5a3262da457cd3ec3c677af8d01f/bc10c/INSTAR_Cloud_Alexa_Skill_Example_01.webp 460w", "/en/static/885b5a3262da457cd3ec3c677af8d01f/966d8/INSTAR_Cloud_Alexa_Skill_Example_01.webp 920w", "/en/static/885b5a3262da457cd3ec3c677af8d01f/445df/INSTAR_Cloud_Alexa_Skill_Example_01.webp 1380w", "/en/static/885b5a3262da457cd3ec3c677af8d01f/fad48/INSTAR_Cloud_Alexa_Skill_Example_01.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/885b5a3262da457cd3ec3c677af8d01f/e83b4/INSTAR_Cloud_Alexa_Skill_Example_01.jpg 230w", "/en/static/885b5a3262da457cd3ec3c677af8d01f/e41a8/INSTAR_Cloud_Alexa_Skill_Example_01.jpg 460w", "/en/static/885b5a3262da457cd3ec3c677af8d01f/4d836/INSTAR_Cloud_Alexa_Skill_Example_01.jpg 920w", "/en/static/885b5a3262da457cd3ec3c677af8d01f/89b2d/INSTAR_Cloud_Alexa_Skill_Example_01.jpg 1380w", "/en/static/885b5a3262da457cd3ec3c677af8d01f/b17f8/INSTAR_Cloud_Alexa_Skill_Example_01.jpg 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/885b5a3262da457cd3ec3c677af8d01f/4d836/INSTAR_Cloud_Alexa_Skill_Example_01.jpg",
              "alt": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "title": "Wie man INSTAR Cloud Alexa Skill nutzt",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`An overview of all cloud commands can be found at `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/INSTAR_Cloud/Alexa_Cloud_Skills/INSTAR_Alexa_Cloud_Commands/"
      }}>{`INSTAR Alexa Cloud Commands`}</a></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      